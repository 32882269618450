
import { GetStaticPropsContext, GetStaticPropsResult } from "next";
import { DrupalNode } from "next-drupal";
import { getGlobalElements } from "lib/utils/drupal/get-global-elements";


import { drupal } from "lib/utils/drupal/drupal";
import Layout from '@/components/layouts/Layout';
import { getParams } from "lib/utils/drupal/get-params";
import { revalidateTimeout } from "lib/constants";

import { useEffect, useState } from 'react';
import { NodePageProps } from "pages/[...slug]";
import { Node } from "components/node";
import getContent from "@/utils/drupal/get-content";
import { homeTitle } from 'lib/constants';

const Home = ({
    content,
    blocks,
    theme,
    preFooter,
    meta,
    canonical,
    ...props
}: NodePageProps) => {
    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(() => {
        !sessionStorage.getItem('popup')
            && setShowPopUp(true);
    }, [showPopUp]);

    return (
        <>
            <Layout blocks={blocks} theme={theme ? 'dark' : 'light'} layoutType='base' preFooter={preFooter} meta={meta} canonical={canonical}>
                <Node content={content} showPopUp={showPopUp} {...props} />
            </Layout>
        </>
    );
};

export default Home;

export async function getStaticProps(context: GetStaticPropsContext): Promise<GetStaticPropsResult<NodePageProps>> {

    const [resource] = await drupal.getResourceCollectionFromContext<DrupalNode[]>(
        "node--landing_page", context, {
        params: getParams("node--landing_page").addFilter("title", homeTitle)
            .addSort("created", "DESC")
            .getQueryObject(),
    }
    );

    const contentObject = await getContent(resource, "node--landing_page", context);

    contentObject.content.views.recipes = await drupal.getResourceCollectionFromContext<DrupalNode[]>(
        "node--recipe", context, {
        params: getParams("node--recipe", "card")
            .addSort("created", "DESC")
            .addPageLimit(4)
            .getQueryObject(),
    });

    return {
        props: {
            ...(await getGlobalElements(context)),
            content: contentObject.content,
            theme: contentObject.content.node.field_dark_banner != undefined ? contentObject.content.node.field_dark_banner : false,
            preFooter: contentObject.content.node.field_show_prefooter != undefined ? contentObject.content.node.field_show_prefooter : true,
            meta: contentObject.content.node.metatag ? contentObject.content.node.metatag : null,
            canonical: "/"
        },
        revalidate: revalidateTimeout
    };
}